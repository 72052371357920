dmx.Component('radio', {

  extends: 'form-element',

  initialData: {
    checked: false,
  },

  attributes: {
    checked: {
      type: Boolean,
      default: false,
      alwaysUpdate: true,
    },
  },

  methods: {
    select (check) {
      this._select(check);
    },
  },

  init (node) {
    dmx.Component('form-element').prototype.init.call(this, node);

    node.type = 'radio';
    node.checked = this.props.checked;
    node.defaultChecked = this.props.checked;

    if (this.props.checked) {
      this.set('checked', true);
    }
  },

  performUpdate (updatedProps) {
    dmx.Component('form-element').prototype.performUpdate.call(this, updatedProps);

    if (updatedProps.has('checked')) {
      this.$node.defaultChecked = this.props.checked;
      if (this.$node.checked != this.props.checked) {
        this.$node.checked = this.props.checked;
        this.set('checked', this.props.checked);
        dmx.nextTick(() => this.dispatchEvent("updated"));
      }
    }
  },

  _select (check) {
    this.$node.checked = (check !== false);
    this.set('checked', this.$node.checked);
    this.dispatchEvent(this.$node.checked ? 'checked' : 'unchecked');
    dmx.nextTick(() => this.dispatchEvent("updated"));
  },

  _changeHandler (event) {
    if (this.$node.dirty) this._validate();

    this.set('checked', this.$node.checked);
    this.dispatchEvent(this.$node.checked ? 'checked' : 'unchecked');
    dmx.nextTick(() => this.dispatchEvent("updated"));

    // trigger change on other radios with the same name
    if (this.$node.checked) {
      const radios = document.querySelectorAll(`input[type="radio"][name="${this.$node.name}"]`);
      for (const radio of radios) {
        if (radio !== this.$node) {
          radio.dispatchEvent(new Event('change', { bubbles: true }));
        }
      }
    }
  },

});
